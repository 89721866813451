<template>
<div>
      <p class="msgDesBox" v-show="msgDesFlag">{{msgDes}}</p>
      <p class="msgDesBoxs" v-show="NoMsgDesFlag">{{NoMsgDes}}</p>
      <el-row :class="msgDesFlag?'footer noTop':'footer'">
          <el-col :span="12" class="text-left">
            <el-col :span="6">
              <label>
                <input
                  type="checkbox"
                  :checked="selectedAll"
                  @change="modifySelected()"
                /><span>全选</span>
              </label>
            </el-col>
            <el-col
              :span="4"
              class="text-center pointerDiv"
              @click.native="handleMoreDel()"
              >删除</el-col
            >
            <el-col :span="6" class="text-center" @click.native="handleAllDel()"
              >清空购物车</el-col
            >
            <el-col :span="6" class="text-center" @click.native="copyCartFun()"
              >复制购物车</el-col
            >
          </el-col>
          <el-col :span="5">
            <el-col :span="4">
                <p style="margin-top:3px">赠品：</p>
              </el-col>
            <el-col :span="20">
                <el-radio v-model="cancelGift" label="0" style="margin-top:3px">带活动赠品</el-radio>
                <el-radio v-model="cancelGift" label="1" style="margin-top:3px">本次不带</el-radio>
            </el-col>
          </el-col>
                <!-- <el-radio v-model="cancelGift" label="2" style="margin-top:3px">取消活动</el-radio> -->

          <el-col :span="12" class="text-right">
            <el-col :span="8" class="countWraper">
              <p>已选中<span class="count">{{goodsPrice.isNoBuyNum}}</span>种未购商品</p>
              <p>已选中<span class="count">{{ typeQuantity }}</span>种商品</p>
            </el-col>
            <el-col :span="11" class="text-center"
              >总计(不含运费) :<span class="total">{{
                goodsPrice.goodsPriceSum | capitalize
              }}</span></el-col
            >
            <div :span="5" @click="jumpAmount" class="closeAcount text-center">
              去结算  
            </div>
          </el-col>

          <el-dialog
            class="copyTable"
            title="复制购物车"
            center
            :visible.sync="dialogTableVisible"
            width="1200px"
            :close-on-click-modal="true"
          >
            <el-checkbox
              class="checkAll"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-scrollbar>
              <el-table
                border
                :data="memberStoreList"
                style="width: 100%"
                empty-text="无可复制门店"
              >
                <el-table-column label="店铺名称" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                      :label="scope.row.enterpriseName"
                      true-label="true"
                      false-label="false"
                      v-model="scope.row.flag"
                      @change="handleCheckedCitiesChange(scope.row.flag, scope.row)"
                    ></el-checkbox>
                    <!-- {{ scope.row.enterpriseName }} -->
                  </template>
                </el-table-column>
                <el-table-column label="地址" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.addressProvinceString
                    }}{{ scope.row.addressCityString }}{{ scope.row.addressAreaString
                    }}{{ scope.row.address }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleCopy(scope.row.id)"
                      >复制购物车到当前门店</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
            <span slot="footer" class="dialog-footer">
              <el-button align="right" type="danger" @click="AllhandleCopy"
                >复制购物车到选中门店</el-button
              >
            </span>
          </el-dialog>
        </el-row>
</div>
 
</template>

<script>
import Qs from "qs";
import {getMostExcellentCouponRemind} from "api/order.js";
import { GetMemberStore, CopyCart } from "api/member.js";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "CartBottom",
  data() {
    return {
      cancelGift:'0',
      checkAll: false,
      checkedIds: [], // 初始选中的值
      dialogTableVisible: false,
      memberStoreList: [],
      msgDesFlag:false,
      msgDes:'',
      NoMsgDesFlag:false,
      NoMsgDes:''
    };
  },
  
  computed: {
    ...mapGetters({
      goodsPrice: "goodsPrice",
      typeQuantity: "typeQuantity",
    }),
    ...mapState({
      selectedAll: (state) => state.selectedAll,
      goods: (state) => state.goods,
      goodsFreeMail: (state) => state.goodsFreeMail,
    }),
  },
  created() {
    GetMemberStore().then((res) => {
      let memberStoreId = Qs.parse(
        sessionStorage.getItem("memberData")
      ).memberStoreId;
      let memberArr = res.data.data;
      memberArr.forEach((item) => {
        if (item.auditStatus == 4) {
          if (item.id !== memberStoreId) {
            (item.flag = false), this.memberStoreList.push(item);
          }
        }
      });
    });
  },
  watch: {
    goodsPrice() {
       this.getMostExcellentCouponRemindFun()
    }
  },
  methods: {
    // 全选/取消全选
    handleCheckAllChange(val) {
      let checkedIds = [];
      this.memberStoreList.forEach((item) => {
        checkedIds.push(item.id);
        item.flag = val ? true : false;
      });
      this.checkedIds = val ? checkedIds : [];
    },
    // 改变一个选项状态时
    handleCheckedCitiesChange(value, item) {
      item.flag = value;
      let checkedIds = [];
      this.memberStoreList.forEach((item) => {
        if (item.flag == "true") {
          checkedIds.push(item.id);
        }
      });
      this.checkedIds = checkedIds;
      let checkedCount = this.checkedIds.length;
      this.checkAll = checkedCount === this.memberStoreList.length;
    },
    ...mapMutations({
      modifySelected: "modifySelected",
    }),
    ...mapActions({
      handleMoreDel: "handleMoreDel",
      handleAllDel: "handleAllDel",
    }),
    getMostExcellentCouponRemindFun(){
        // ------------------------------
        let orderDatas=[];
        let tenantIds=this.goods[0].tenantId;
       for (var k = 0; k < this.goods.length; k++) {
          if (this.goods[k].flag) {
            orderDatas.push(
             {
                      "goodsId":this.goods[k].productId,
                      "categoryId":this.goods[k].product.categoryIds,
                      "amount":Number(this.goods[k].quantity)*Number(this.goods[k].product.price).toFixed(2)
                  }
            )
          }
        }
       //调取获取订单加购一定金额会有更佳优惠券提示接口
       
       let datas={
              "orderAmount":Number(this.goodsPrice.goodsPriceSum),
              "orderData":orderDatas,
              "memberStoreId":Qs.parse(sessionStorage.getItem("memberData")).memberStoreId,
              "tenantId":tenantIds,
              "notBuyCount":this.goodsPrice.isNoBuyNum
      }
       getMostExcellentCouponRemind(datas).then((res) => {
         if(JSON.stringify(res.data.data.universalData) !== '{}' ){
              console.log('通用优惠券数据you')
                // 通用可使用的优惠券信息
                if(Number(res.data.data.couponAmount)>0){
                   this.msgDesFlag=true
                   this.msgDes='当前可减'+res.data.data.couponAmount+'元,再买'+res.data.data.universalData.purchaseAmount+'元，可减'+res.data.data.universalData.memberStoreCouponCheckedVO.promotionValue+'元'
                }else{
                  this.msgDesFlag=true
                  this.msgDes='再买'+res.data.data.universalData.purchaseAmount+'元，可减'+res.data.data.universalData.memberStoreCouponCheckedVO.promotionValue+'元'
                }
               
          }else{
            if(Number(res.data.data.couponAmount)>0){
              this.msgDesFlag=true
              this.msgDes='当前可减'+res.data.data.couponAmount+'元'
            }else{
              this.msgDesFlag=false
              this.msgDes=''
            }
          }

// 未购券
          if(JSON.stringify(res.data.data.notBuyData) !== '{}' ){
                this.NoMsgDesFlag=true
                  this.NoMsgDes='再买'+res.data.data.notBuyData.purchaseCount+'个未购商品,可减'+res.data.data.notBuyData.memberStoreCouponCheckedVO.promotionValue+'元'
              
          }else{
            this.NoMsgDesFlag=false
            this.NoMsgDes=''
          }
          if(JSON.stringify(res.data.data.singleGoodsData) !== '[]'){
            //单品优惠券不为空
             this.$emit("ChangeDatasFun",res.data.data.singleGoodsData)
          }else{
             this.$emit("ChangeDatasFun",[])
          }
    //singleGoodsData: []   单品优惠券数据
    //universalData: []    通用可使用的优惠券信息
        })
        // ------------------------------
    },
    jumpAmount() {
      
      if (this.typeQuantity < 1) {
        this.$message({
          message: "最少购买一个商品",
          type: "warning",
        });
      } else {
            let moreIds = "";
        for (var i = 0; i < this.goods.length; i++) {
          if (this.goods[i].flag) {
            moreIds += this.goods[i].id + ",";
          }
        }
        for (let item of this.goodsFreeMail) {
          if (item.flag) {
            moreIds += item.id + ",";
          }
        }
         this.$router.push({
                    name: "GoodsAmount",
                    query: {
                      ids: moreIds,
                      cancelGift:this.cancelGift,
                      notBuyCount:this.goodsPrice.isNoBuyNum
                    },
                  });
      }
    },
    // 赋值选中门店购物车
    AllhandleCopy() {
      let moreIds = "";
      for (var i = 0; i < this.goods.length; i++) {
        if (this.goods[i].flag) {
          moreIds += this.goods[i].id + ",";
        }
      }
      for (let item of this.goodsFreeMail) {
        if (item.flag) {
          moreIds += item.id + ",";
        }
      }

      let memberStoreId = "";
      if (this.checkedIds.length > 0) {
        this.checkedIds.forEach((item) => {
          memberStoreId += item + ",";
        });
      } else {
        this.$message({
          message: "最少勾选一个门店",
          type: "warning",
        });
        return false;
      }
      let datas = {
        ids: moreIds,
        toMemberStoreId: memberStoreId,
      };
      CopyCart(datas).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          // 关闭弹窗
          this.dialogTableVisible = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          // 关闭弹窗
          this.dialogTableVisible = false;
        }
      });
    },
    // 复制当前门店购物车
    handleCopy(memberStoreId) {
      let moreIds = "";
      for (var i = 0; i < this.goods.length; i++) {
        if (this.goods[i].flag) {
          moreIds += this.goods[i].id + ",";
        }
      }
      for (let item of this.goodsFreeMail) {
        if (item.flag) {
          moreIds += item.id + ",";
        }
      }
      let datas = {
        ids: moreIds,
        toMemberStoreId: memberStoreId,
      };
      CopyCart(datas).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          // 关闭弹窗
          this.dialogTableVisible = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          // 关闭弹窗
          this.dialogTableVisible = false;
        }
      });
    },

    // 复制购物车
    copyCartFun() {
      if (this.typeQuantity < 1) {
        this.$message({
          message: "最少勾选一个商品",
          type: "warning",
        });
      } else {
        this.dialogTableVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.checkAll {
  margin-bottom: 20px;
}

/deep/.el-radio__input.is-checked .el-radio__inner{
      border-color: #ff3333;
    background: #ff3333;
}
/deep/.el-radio__input.is-checked+.el-radio__label{
  color: #ff3333;
}
/* 表格样式 */
/* th样式 */
/deep/.copyTable .el-table .has-gutter th {
  background-color: #fafafa;
  text-align: center;
}

/deep/.el-dialog__header {
  padding: 10px 20px 10px;

  .el-dialog__title {
    font-size: 17px;
    line-height: 40px;
    font-weight: bold;
    color: #000;
  }
}

/deep/.el-dialog__body {
  padding: 0px 25px 40px;
}

/deep/.el-scrollbar {
  .el-scrollbar__wrap {
    max-height: 300px; // 最大高度
    overflow-x: hidden; // 隐藏横向滚动栏
    padding-bottom: 20px;
  }
}

.NoMerberStore {
  line-height: 40px;
  font-size: 16px;
  text-align: center;
}
.msgDesBox{
  width:99%;
  text-align: right;
  line-height:50px;
  color: #000;
  font-weight: bold;
  font-size: 15px;
}
.msgDesBoxs{
  width:99%;
  text-align: right;
  line-height:50px;
  color: #000;
  font-weight: bold;
  font-size: 15px;
}
.noTop{
   margin-top:0px!important;
}
.footer {
  width: 1200px;
  height: 70px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 13px;
  line-height: 18px;
  margin-top: 30px;
  margin-bottom: 50px;

  .pointerDiv {
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  label {
    padding-left: 10px;
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
      cursor: pointer;

      &:hover {
        color: #ff3333;
      }
    }
  }

  .text-center {
    cursor: pointer;

    &:hover {
      color: #ff3333;
    }
  }

  .text-right {
    display: flex;
    align-items: center;

    .total {
      color: #ff3333;
      line-height: 25px;
      font-size: 18px;
      font-weight: bold;
      padding-left: 10px;
    }

    .countWraper {
      line-height: 18px;
      text-align: right;
      .count {
        color: #ff3333;
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .closeAcount {
      width: 130px;
      height: 70px;
      background: #ff3333;
      color: #fff;
      font-size: 18px;
      line-height: 70px;
      cursor: pointer;
    }
  }
}
</style>